<template>
	<div class="main">
		<!-- <div class="location">
			<div class="cur-location">
				<span style="line-height: 1">您所在的位置：</span>
			</div>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>系统设置</el-breadcrumb-item>
				<el-breadcrumb-item>小程序配置</el-breadcrumb-item>
			</el-breadcrumb>
		</div> -->
		<div class="content">
			<el-tabs v-model="activeName" @tab-click="tabsClick" class="commonTab">
				<el-tab-pane label="小程序配置" name="first">
					<div class="modular">
						<div class="modular_title">
							<div>小程序配置</div>
						</div>
						<div class="modular_row">
							<div>
								<span>小程序code</span>
								<span>{{ getConfData.code }}</span>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>小程序名称</span>
								<span>{{ getConfData.name }}</span>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>微信小程序appid</span>
								<span>{{ getConfData.wxMiniAppid }}</span>
							</div>
							<div>
								<el-button
									v-if="!getConfData.wxMiniAppid"
									size="mini"
									@click="openDialog('conifWxMiniapp')"
								>
									配置
								</el-button>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>微信小程序AppSecret</span>
								<span></span>
							</div>
							<div>
								<el-button size="mini" @click="openDialog('updateWxMiniappKey')">重置</el-button>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="公众号配置" name="second">
					<div class="modular">
						<div class="modular_title">
							<div>公众号配置</div>
						</div>
						<div class="modular_row">
							<div>
								<span>公众号code</span>
								<span>{{ getConfData.openCode }}</span>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>公众号名称</span>
								<span>{{ getConfData.openName }}</span>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>公众号appid</span>
								<span>{{ getConfData.wxOpenAppid }}</span>
							</div>
							<div>
								<el-button
									v-if="!getConfData.wxOpenAppid"
									size="mini"
									@click="openDialog('conifWxOpenapp')"
								>
									配置
								</el-button>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>公众号小程序AppSecret</span>
								<span></span>
							</div>
							<div>
								<el-button size="mini" @click="openDialog('updateWxOpenappKey')">重置</el-button>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="商户号配置" name="third">
					<div class="modular">
						<div class="modular_title">
							<div>商户号配置</div>
						</div>
						<div class="modular_row">
							<div>
								<span>商户号</span>
								<span>{{ getConfData.mchId }}</span>
							</div>
							<div>
								<el-button size="mini" @click="openDialog('conifWxMch', 'all')">配置</el-button>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>商户名称</span>
								<span>{{ getConfData.mchNm }}</span>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>商户api密钥</span>
								<span></span>
							</div>
							<div>
								<el-button size="mini" @click="openDialog('updateWxMchKey')">重置</el-button>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>商户apiV3Key</span>
								<span></span>
							</div>
							<div>
								<el-button size="mini" @click="openDialog('conifWxMch', 'apiV3Key')">重置</el-button>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>商户serialNo</span>
								<span></span>
							</div>
							<div>
								<el-button size="mini" @click="openDialog('conifWxMch', 'serialNo')">重置</el-button>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>商户cert_p12证书</span>
								<span></span>
							</div>
							<div>
								<el-button size="mini" @click="openDialog('mchCertUpload', 0)">重置</el-button>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>商户cert_pem证书</span>
								<span></span>
							</div>
							<div>
								<el-button size="mini" @click="openDialog('mchCertUpload', 1)">重置</el-button>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>商户key_pem证书</span>
								<span></span>
							</div>
							<div>
								<el-button size="mini" @click="openDialog('mchCertUpload', 2)">重置</el-button>
							</div>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<el-dialog :title="title" :visible.sync="visible" :width="width" center :close-on-click-modal="false">
			<el-form
				ref="conifWxMiniappForm"
				:model="conifWxMiniappForm"
				label-width="150px"
				:class="width == '800px' ? 'form' : ''"
				:rules="rules"
				v-if="FUNType == 'conifWxMiniapp'"
			>
				<el-form-item :label="$t('AppletFill.xappid')" prop="appid">
					<el-input size="mini" v-model="conifWxMiniappForm.appid"></el-input>
				</el-form-item>
				<el-form-item :label="$t('AppletFill.xsecretKey')" prop="secretKey">
					<el-input size="mini" v-model="conifWxMiniappForm.secretKey"></el-input>
				</el-form-item>
			</el-form>
			<el-form
				ref="conifWxOpenappForm"
				:model="conifWxOpenappForm"
				label-width="150px"
				:class="width == '800px' ? 'form' : ''"
				:rules="rules"
				v-else-if="FUNType == 'conifWxOpenapp'"
			>
				<el-form-item :label="$t('AppletFill.gappid')" prop="appid">
					<el-input size="mini" v-model="conifWxOpenappForm.appid"></el-input>
				</el-form-item>
				<el-form-item :label="$t('AppletFill.gsecretKey')" prop="secretKey">
					<el-input size="mini" v-model="conifWxOpenappForm.secretKey"></el-input>
				</el-form-item>
			</el-form>
			<el-form
				ref="conifWxMchForm"
				:model="conifWxMchForm"
				label-width="120px"
				:class="width == '800px' ? 'form' : ''"
				:rules="rules"
				v-else-if="FUNType == 'conifWxMch'"
			>
				<el-form-item :label="$t('AppletFill.mchId')" prop="mchId">
					<el-input size="mini" v-model="conifWxMchForm.mchId"></el-input>
				</el-form-item>
				<el-form-item :label="$t('AppletFill.mchNm')" prop="mchNm" v-if="conifWxMchKey == 'all'">
					<el-input size="mini" v-model="conifWxMchForm.mchNm"></el-input>
				</el-form-item>
				<el-form-item
					:label="$t('AppletFill.mchKey')"
					prop="mchKey"
					v-if="conifWxMchKey == 'mchKey' || conifWxMchKey == 'all'"
				>
					<el-input size="mini" v-model="conifWxMchForm.mchKey"></el-input>
				</el-form-item>
				<el-form-item
					:label="$t('AppletFill.apiV3Key')"
					prop="apiV3Key"
					v-if="conifWxMchKey == 'apiV3Key' || conifWxMchKey == 'all'"
				>
					<el-input size="mini" v-model="conifWxMchForm.apiV3Key"></el-input>
				</el-form-item>
				<el-form-item
					:label="$t('AppletFill.serialNo')"
					prop="serialNo"
					v-if="conifWxMchKey == 'serialNo' || conifWxMchKey == 'all'"
				>
					<el-input size="mini" v-model="conifWxMchForm.serialNo"></el-input>
				</el-form-item>
			</el-form>
			<el-form
				ref="updateWxMchKeyForm"
				:model="updateWxMchKeyForm"
				label-width="90px"
				:class="width == '800px' ? 'form' : ''"
				:rules="rules"
				v-else-if="FUNType == 'updateWxMchKey'"
			>
				<el-form-item :label="$t('AppletFill.mchId')" prop="mchId">
					<el-input size="mini" v-model="updateWxMchKeyForm.mchId"></el-input>
				</el-form-item>
				<el-form-item :label="$t('AppletFill.keyValue')" prop="keyValue">
					<el-input size="mini" v-model="updateWxMchKeyForm.keyValue"></el-input>
				</el-form-item>
			</el-form>
			<el-form
				ref="mchCertUploadForm"
				:model="mchCertUploadForm"
				label-width="80px"
				:class="width == '800px' ? 'form' : ''"
				v-else-if="FUNType == 'mchCertUpload'"
			>
				<el-form-item :label="$t('AppletFill.mchId')" prop="mchId">
					<el-input size="mini" v-model="mchCertUploadForm.mchId" disabled></el-input>
				</el-form-item>
				<el-form-item :label="$t('AppletFill.file')" prop="keyValue">
					<el-upload
						ref="upload"
						class="upload-demo"
						action="#"
						multiple
						:auto-upload="false"
						:limit="1"
						:file-list="fileList"
						:on-change="fileListChange"
						:on-exceed="fileError"
					>
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
				</el-form-item>
			</el-form>
			<el-form
				ref="updateWxMiniappKeyForm"
				:model="updateWxMiniappKeyForm"
				label-width="150px"
				:class="width == '800px' ? 'form' : ''"
				:rules="rules"
				v-else-if="FUNType == 'updateWxMiniappKey'"
			>
				<el-form-item :label="$t('AppletFill.xsecretKey')" prop="appKey">
					<el-input size="mini" v-model="updateWxMiniappKeyForm.appKey"></el-input>
				</el-form-item>
			</el-form>
			<el-form
				ref="updateWxOpenappKeyForm"
				:model="updateWxOpenappKeyForm"
				label-width="150px"
				:class="width == '800px' ? 'form' : ''"
				:rules="rules"
				v-else-if="FUNType == 'updateWxOpenappKey'"
			>
				<el-form-item :label="$t('AppletFill.gsecretKey')" prop="appKey">
					<el-input size="mini" v-model="updateWxOpenappKeyForm.appKey"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="visible = false">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			getConfData: {
				code: '',
				isUp: '',
				mchId: '',
				mchNm: '',
				name: '',
				openCode: '',
				openName: '',
				organizationId: '',
				wxMiniAppid: '',
				wxOpenAppid: ''
			},
			visible: false,
			title: '标题',
			rules: {
				appid: [{ required: true, message: '请输入appid', trigger: 'blur' }],
				appKey: [{ required: true, message: '请输入AppSecret', trigger: 'blur' }],
				apiV3Key: [{ required: true, message: '请输入商户apiV3Key', trigger: 'blur' }],
				mchId: [{ required: true, message: '请输入商户号', trigger: 'blur' }],
				mchKey: [{ required: true, message: '请输入商户api密钥', trigger: 'blur' }],
				mchNm: [{ required: true, message: '请输入商户名称', trigger: 'blur' }],
				serialNo: [{ required: true, message: '请输入商户serialNo', trigger: 'blur' }],
				secretKey: [{ required: true, message: '请输入AppSecret', trigger: 'blur' }]
			},
			FUNType: '',
			conifWxMiniappForm: {
				appid: '',
				secretKey: ''
			},
			conifWxOpenappForm: {
				appid: '',
				secretKey: ''
			},
			conifWxMchForm: {
				apiV3Key: '',
				mchId: '',
				mchKey: '',
				mchNm: '',
				serialNo: '',
				shareProfit: true
			},
			updateWxMchKeyForm: {
				keyType: '',
				keyValue: '',
				mchId: ''
			},
			mchCertUploadForm: {
				mchId: ''
			},
			updateWxMiniappKeyForm: {
				app: '',
				appKey: ''
			},
			updateWxOpenappKeyForm: {
				app: '',
				appKey: ''
			},
			fileList: [],
			fileNum: 0,
			activeName: 'first',
			width: '800px',
			conifWxMchKey: ''
		}
	},
	mounted() {
		this.getConf()
	},
	methods: {
		tabsClick(e, t) {
			console.log(e, t)
			this.activeName = e._props.name
		},
		fileError() {
			this.$message.error('只能上传一个文件，请删除后再上传')
		},
		fileListChange(file) {
			console.log(file)
			this.fileList[0] = file
		},
		openDialog(str, num) {
			console.log(str)
			this.FUNType = str
			switch (str) {
				case 'conifWxMiniapp':
					this.$nextTick(() => {
						this.$refs.conifWxMiniappForm.resetFields()
						this.conifWxMiniappForm.appid = this.getConfData.wxMiniAppid
					})
					this.title = '小程序配置'
					this.width = '400px'
					this.visible = true
					break
				case 'conifWxOpenapp':
					this.$nextTick(() => {
						this.$refs.conifWxOpenappForm.resetFields()
						this.conifWxOpenappForm.appid = this.getConfData.wxOpenAppid
					})
					this.title = '公众号配置'
					this.width = '400px'
					this.visible = true
					break
				case 'conifWxMch':
					this.conifWxMchKey = num
					if (num == 'all') {
						this.width = '400px'
					} else {
						this.width = '400px'
					}
					this.$nextTick(() => {
						this.$refs.conifWxMchForm.resetFields()
						this.conifWxMchForm.mchId = this.getConfData.mchId || ''
					})
					this.title = '商户号配置'
					this.visible = true
					break
				case 'updateWxMchKey':
					this.$nextTick(() => {
						this.$refs.updateWxMchKeyForm.resetFields()
						this.updateWxMchKeyForm.mchId = this.getConfData.mchId || ''
					})
					this.title = '商户密钥重置'
					this.width = '400px'
					this.visible = true
					break
				case 'mchCertUpload':
					this.fileNum = num
					if (this.fileNum == 0) this.title = '商户cert_p12证书重置'
					if (this.fileNum == 1) this.title = '商户cert_pem证书'
					if (this.fileNum == 2) this.title = '商户key_pem证书重置'
					this.mchCertUploadForm.mchId = this.getConfData.mchId
					this.width = '400px'
					this.visible = true
					this.$nextTick(() => {
						this.$refs.upload.clearFiles()
						this.fileList[0] = undefined
					})
					break
				case 'updateWxMiniappKey':
					this.$nextTick(() => {
						this.$refs.updateWxMiniappKeyForm.resetFields()
					})
					this.title = '小程序AppSecret重置'
					this.width = '400px'
					this.visible = true
					break
				case 'updateWxOpenappKey':
					this.$nextTick(() => {
						this.$refs.updateWxOpenappKeyForm.resetFields()
					})
					this.title = '公众号AppSecret重置'
					this.width = '400px'
					this.visible = true
					break
			}
		},
		createFormData() {
			const form = new FormData()
			form.append('file', this.fileList[0].raw)
			return form
		},
		save() {
			switch (this.FUNType) {
				case 'conifWxMiniapp':
					this.$refs.conifWxMiniappForm.validate(valid => {
						if (valid) {
							this.ajax.post('/admin/miniApp/conf/conifWxMiniapp', this.conifWxMiniappForm).then(() => {
								this.$message.success(this.$t('common.success'))
								this.visible = false
								this.getConf()
							})
						}
					})
					break
				case 'conifWxOpenapp':
					this.$refs.conifWxOpenappForm.validate(valid => {
						if (valid) {
							this.ajax.post('/admin/miniApp/conf/conifWxOpenapp', this.conifWxOpenappForm).then(() => {
								this.$message.success(this.$t('common.success'))
								this.visible = false
								this.getConf()
							})
						}
					})
					break
				case 'conifWxMch':
					this.$refs.conifWxMchForm.validate(valid => {
						if (valid) {
							this.ajax.post('/admin/miniApp/conf/conifWxMch', this.conifWxMchForm).then(() => {
								this.$message.success(this.$t('common.success'))
								this.visible = false
								this.getConf()
							})
						}
					})
					break
				case 'updateWxMchKey':
					this.$refs.updateWxMchKeyForm.validate(valid => {
						if (valid) {
							this.ajax.post('/admin/miniApp/conf/updateWxMchKey', this.updateWxMchKeyForm).then(() => {
								this.$message.success(this.$t('common.success'))
								this.visible = false
								this.getConf()
							})
						}
					})
					break
				case 'mchCertUpload':
					if (!this.fileList[0]) {
						this.$message.error('请提交文件')
					} else {
						this.ajax
							.post(
								`/admin/miniApp/conf/mchCertUpload?mchId=${this.getConfData.mchId}&filename=${
									this.fileList[0].name
								}&certType=${this.$enum.wxMchCertType[this.fileNum].code}`,
								this.createFormData(),
								{
									'Content-Type': 'multipart/form-data'
								}
							)
							.then(() => {
								this.$message.success(this.$t('common.success'))
								this.visible = false
								this.getConf()
							})
					}
					break
				case 'updateWxMiniappKey':
					this.$refs.updateWxMiniappKeyForm.validate(valid => {
						if (valid) {
							this.ajax
								.post('/admin/miniApp/conf/updateWxMiniappKey', this.updateWxMiniappKeyForm)
								.then(() => {
									this.$message.success(this.$t('common.success'))
									this.visible = false
									this.getConf()
								})
						}
					})
					break
				case 'updateWxOpenappKey':
					this.$refs.updateWxOpenappKeyForm.validate(valid => {
						if (valid) {
							this.ajax
								.post('/admin/miniApp/conf/updateWxOpenappKey', this.updateWxOpenappKeyForm)
								.then(() => {
									this.$message.success(this.$t('common.success'))
									this.visible = false
									this.getConf()
								})
						}
					})
					break
			}
		},
		getConf() {
			this.ajax.get('/admin/miniApp/conf/getConf').then(res => {
				this.getConfData = res
			})
		}
	}
}
</script>
<style lang="scss" scoped>
@import '../assets/css/global.scss';
.content {
	width: 100%;
	height: calc(100% - 26px);
	overflow: auto;
}
.modular {
	width: 100%;
	margin-bottom: 10px;
	.modular_title {
		width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		// color: #fff;
		@include modular_title_color(#fff);
		& div:nth-child(1) {
			font-size: 16px;
			height: 30px;
			font-weight: bold;
		}
		& div:nth-child(2) {
			margin-left: 20px;
		}
	}
	.modular_row {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #625a5a;
		line-height: 50px;
		height: 50px;
		font-size: 12px;
		font-weight: bold;
		@include modular_title_color(#fff);
		& div:nth-child(1) span:nth-child(1) {
			display: inline-block;
			width: 200px;
			margin-right: 50px;
		}
	}
	.table {
		margin: 0;
	}
}
</style>
